exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-notfound-js": () => import("./../../../src/pages/notfound.js" /* webpackChunkName: "component---src-pages-notfound-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-therapists-js": () => import("./../../../src/pages/therapists.js" /* webpackChunkName: "component---src-pages-therapists-js" */)
}

